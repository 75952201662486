import Header from "./header/header";
import Footer from "./footer/footer";
import Susisiekimas from "./Susisiekimas";

function ESParama(){
    return(
        <>
            <Header activeNav={4}></Header>
            <div className="esParam">
                <div className="esParamTop">
                    <div>
                    <img src="assets/esparamasaulei.png" alt="ES parama saulės elektrinėms"></img>
                    <img src="assets/esparama.png" alt="parama"></img>
                        {/*     
                        <h3>ES projektas</h3>
                        <div className="textDiv">   UAB Ukmergės staklės įgyvendina projektą – Ekoinovacijų diegimas UAB „Ukmergės staklės“. Siekiamo įgyvendinti projekto tikslas– įmonėje įdiegti ekotechnologijas, siekiant sumažinti pramonės poveikį aplinkai, taip pat išplėtoti precizinių mechatronikos komponentų gamybos infrastruktūrą, įsigyjant naujus našius bei inovatyvius kompiuterinio valdymo metalo apdirbimo įrenginius. Įsigijus minėtą įrangą įmonė prisidės prie mažesnio žaliavų, elektros energijos, pagalbinių medžiagų naudojimo, eliminuos neatitiktinio produkto galimybę.
                                <br/><br/>UAB „Ukmergės staklės“ investuoja į ekologiškesnius įrengimus, naudojančius mažiau elektros energijos, tikslesnio apdirbimo, taip sumažindama neatitiktinio produkto rizikas, kas padės sunaudoti mažiau žaliavų, elektros energijos, aušinimo emulsijos ir tepalo, bus mažiau atliekų. Siekiamas projekto rezultatas - įmonėje įdiegti ekotechnologijas ir sumažinti gamybos poveikį aplinkai, įsigyjant naujus, našius bei inovatyvius kompiuterinio valdymo metalo apdirbimo įrenginius. Projektas finansuojamas iš 2014–2020 m. Europos regioninės plėtros fondo, pagal priemonę Nr. 03.3.2- LVPA-K-837 „Eco-inovacijos LT+“.
                                <br/><br/>Projekto biudžetas: 204 730,00 Eur
                                <br/>Projekto finansavimas: 71655,00 Eur
                                <br/><br/>Planuojama projekto veiklų pabaiga: 2022 rugpjūčio mėn.</div>

                                */}
                    </div>
                   {/*  <img src="assets/Logo.svg" alt="logo"></img>  */}
                </div>
            </div>
            <Susisiekimas></Susisiekimas>
            <Footer></Footer>
        </>
    )
}

export default ESParama